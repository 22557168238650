.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  /*background: radial-gradient(circle at 100% 100%, #171717 0, #171717 5px, transparent 5px) 0% 0%/8px 8px no-repeat,
            radial-gradient(circle at 0 100%, #171717 0, #171717 5px, transparent 5px) 100% 0%/8px 8px no-repeat,
            radial-gradient(circle at 100% 0, #171717 0, #171717 5px, transparent 5px) 0% 100%/8px 8px no-repeat,
            radial-gradient(circle at 0 0, #171717 0, #171717 5px, transparent 5px) 100% 100%/8px 8px no-repeat,
            linear-gradient(#171717, #171717) 50% 50%/calc(100% - 6px) calc(100% - 16px) no-repeat,
            linear-gradient(#171717, #171717) 50% 50%/calc(100% - 16px) calc(100% - 6px) no-repeat,
            radial-gradient(at 100% 100%, #f48200 0%, transparent 70%),
            radial-gradient(at 100% 0%, #7e00ff 0%, rgba(0,0,0,0) 70%),
            radial-gradient(at 0% 0%, #ec00ff 0%, rgba(0,0,0,0) 70%),
            #ff0000;
  border: 0;
  border-radius: 8px;
  color: #fff;*/
  font-family: 'Montserrat', sans-serif; 
  font-weight: 0; 
  font-size: 16px; 
  color: #fff; 
  background: linear-gradient(90deg, #0066CC 0%, #c500cc 100%); 
  padding: 10px 50px; 
  border: none; 
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px; 
  border-radius: 50px; 
  transition : 1000ms; 
  transform: translateY(0);
}
.nav-tabs {
  --bs-nav-tabs-border-color: #171717;
}
.nav-link {
  color: #fff;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border: none;
}
.nav-link:focus, .nav-link:hover {
  color: #fff;
}
.modal-body, .modal-content, .modal-backdrop {
  background: transparent !important;
  border: 0 !important;
}
a {
  color: #212529 !important;
  text-decoration: none !important;
}
.card-cont {
  width: 100%;
  max-width: 500px;
  padding: 0 !important;
  /*height: 100vh;
  overflow: auto;*/
}
.exclamation-title {
  position: relative;
  top: -2px;
  height: 25px;
}
.simple-profile{
  width: 175px;
  padding-top: 50px;
  padding-bottom: 10px;
}
.profile{
  width: 100%;
}
.prof-name {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}
.prof-job {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  text-align: left;
}
.bus-desc {
  padding: 20px 0 0 0;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
}
.row-list {
  padding: 15px 0 15px 0;
  border-bottom: 1px solid #383838;
}
.icon-list {
  text-align: right;
}
.icon-class-list {
  position: relative;
  top: 5px;
  font-size: 25px;
  transition : 1000ms; 
  transform: translateY(0);
  cursor: pointer;
}
.icon-small {
  font-size: 20px;
}
.icon-small-big {
  font-size: 30px;
}
.text-list {
  text-align: left;
}
.icon-text-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  cursor: pointer;
}
.icon-text-date {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  cursor: pointer;
}
.icon-class {
  color: #fff;
  font-size: 25px;
  transition : 1000ms; 
  transform: translateY(0);
  cursor: pointer;
}
.icon-class:hover {
  transition : 1000ms; 
  transform : translateY(-6px);
  cursor: pointer;
}
.icon-text {
  padding-top: 6px;
  color: #fff;
  font-size: 11px;
  cursor: pointer;
}
.border-gradient {
  background: radial-gradient(circle at 100% 100%, #171717 0, #171717 5px, transparent 5px) 0% 0%/8px 8px no-repeat,
            radial-gradient(circle at 0 100%, #171717 0, #171717 5px, transparent 5px) 100% 0%/8px 8px no-repeat,
            radial-gradient(circle at 100% 0, #171717 0, #171717 5px, transparent 5px) 0% 100%/8px 8px no-repeat,
            radial-gradient(circle at 0 0, #171717 0, #171717 5px, transparent 5px) 100% 100%/8px 8px no-repeat,
            linear-gradient(#171717, #171717) 50% 50%/calc(100% - 6px) calc(100% - 16px) no-repeat,
            linear-gradient(#171717, #171717) 50% 50%/calc(100% - 16px) calc(100% - 6px) no-repeat,
            radial-gradient(at 100% 100%, #f48200 0%, transparent 70%),
            radial-gradient(at 100% 0%, #7e00ff 0%, rgba(0,0,0,0) 70%),
            radial-gradient(at 0% 0%, #ec00ff 0%, rgba(0,0,0,0) 70%),
            #ff0000;
  border-radius: 8px;
  padding: 7px;
  box-sizing: border-box;
  padding: 15px 0 10px 0;
}
.social {
  color: #fff;
  font-size: 20px;
  text-align: left;
}
.pipe-option {
  border-right: 1px solid #ffffff6c;
  cursor: pointer;
}
.contact-style {
  font-family: 'Montserrat', sans-serif; 
  font-weight: 0; 
  font-size: 16px; 
  color: #fff; 
  background: linear-gradient(90deg, #0066CC 0%, #0066cc 100%); 
  padding: 10px 0px; 
  border: none; 
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px; 
  border-radius: 10px; 
  transition : 1000ms; 
  transform: translateY(0);
  padding-top: 15px;
}
.contact-btn{
  width: 300px;
  font-family: 'Montserrat', sans-serif; 
  font-weight: 0; 
  font-size: 14px; 
  color: #fff; 
  background: linear-gradient(90deg, #0066CC 0%, #0066cc 100%); 
  padding: 10px 50px; 
  border: none; 
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px; 
  border-radius: 10px; 
  transition : 1000ms; 
  transform: translateY(0); 
}
.contact-btn:hover{ 
  transition : 1000ms; 
  transform : translateY(-6px); 
  background: linear-gradient(90deg, #0066CC 0%, #0066cc 100%); 
  color: #ffffff; 
  border: none; 
}
.web-text {
  font-family: 'Montserrat', sans-serif;
  padding-top: 25px;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}
.bus-container {
  position: absolute;
  top: 15px;
  left: 15px;
}

.loading-logo {
  width: 175px;
}
.spinner-custom {
  padding-top: 5px;
  width: 15px;
  height: 15px;
}
.spinner-a {
  color: #ffba00;
}
.spinner-b {
  color: #ff7000;
}
.spinner-c {
  color: #ff0cb9;
}
.spinner-d {
  color: #036ae5;
}
