@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.logo {
  max-height: 30px;
}
.navbar-custom-b {
  padding: 35px 10px !important;
  background-color: #fff !important;
}
.navbar-item {
  color: #7a2873;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  padding-right: 40px;
}
.home-section {
  background-color: #bf7ee4;
  padding: 172px 5px 90px 5px;
}
.home-title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 800;
  padding: 20px 0;
  color: #fff;
}
.home-desc {
  font-size: 14px;
  color: #fff;
}
.home-button {
  background-color: #fff;
  color: #bf7ee4;
  font-size: 15px;
  font-weight: 600;
  border: 0;
  padding: 20px 50px;
  border-radius: 30px;
}
.cards {
  width: 100%;
}
.section-text {
  width: 75%;
}
.simple-section {
  padding: 100px 0 0 0;
}
.small-title {
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  color: #bf7ee4;
  text-align: center;
}
.section-title-b {
  font-size: 50px;
  font-weight: 900;
  line-height: 60px;
  color: #000;
  text-align: center;
  margin-bottom: 10px;
}
.section-desc {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  width: 80%;
}
.space {
  height: 50px;
}
.card-service-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.card-service-item {
  padding: 35px 20px;
}
.card-icon {
  font-size: 40px;
  font-weight: 900;
  color: #bf7ee4;
  text-align: left;
  margin-bottom: 15px;
}
.card-title {
  font-size: 30px;
  font-weight: 800;
  line-height: 35px;
  color: #000;
  text-align: left;
  margin-bottom: 25px;
}
.card-desc {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}