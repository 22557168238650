@font-face {
  font-family: 'Brush Script';
  src: url(./fonts/Brush\ Script.ttf);
}

.App {
  text-align: center;
  width: 100%;
}
.slick-prev:before, .slick-next:before {
  color: #464646;
}

a {
  color: #212529 !important;
  text-decoration: none !important;
}

.hide {
  display: none;
}
.background-andrea {
  background-image: url(./img/andrea/19860.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid rgb(232, 231, 231);
  width: 100%;
  height: calc(100% - 170px);;
  position: absolute;
  background-color: rgb(242, 242, 242);
  border-radius: 20px 20px 0 0;
  top: 120px;
  z-index: -1;
  box-shadow: 0px -4px 31px -15px rgba(0,0,0,0.38);
  opacity: 0.6;
}
.background-optica {
  background-image: url(./img/opticas/back.jpg);
  background-size: cover;
  background-repeat: repeat;
  border: 1px solid rgb(232, 231, 231);
  width: 100%;
  height: calc(100% - 170px);;
  position: absolute;
  background-color: rgb(242, 242, 242);
  border-radius: 20px 20px 0 0;
  top: 170px;
  z-index: -1;
  box-shadow: 0px -4px 31px -15px rgba(0,0,0,0.38);
}
.background-card {
  background-image: url(./img/ferresistemas/1743_a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border: 0px solid rgb(232, 231, 231);
  width: 100%;
  height: calc(100% - 170px);;
  position: absolute;
  background-color: rgb(242, 242, 242);
  /*border-radius: 20px 20px 0 0;*/
  top: 170px;
  z-index: -1;
  box-shadow: 0px -4px 31px -15px rgba(0,0,0,0.38);
}
.background-card-b {
  background-image: url(./img/ferresistemas/agua.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  border: 0px solid rgb(232, 231, 231);
  width: 100%;
  height: calc(100% - 170px);;
  position: absolute;
  background-color: rgb(242, 242, 242);
  /*border-radius: 20px 20px 0 0;*/
  top: 170px;
  z-index: -1;
  box-shadow: 0px -4px 31px -15px rgba(0,0,0,0.38);
}
.card-container {
  width: 100%;
  max-width: 500px;
}
.business-image {
  width: 70%;
  padding: 20px 0 10px 0;
}
.profile-image{
  width: 170px;
  padding: 10px 0 20px 0;
}
.profile-name {
  font-family: 'Roboto', sans-serif;
  font-size: 35px;
  font-weight: 900;
}
.profile-job {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  color: #e3831c;
}
.contact-container {
  padding-top: 30px;
}
.subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 10px;
}
.icons-contact {
  color: #e3831c;
  font-size: 30px;
  cursor: pointer;
}
.business-desc {
  padding: 40px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  text-align: center;
}
.business-container{
  padding: 0px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}
.icons-rrss {
  color: #e3831c;
  font-size: 20px;
  cursor: pointer;
}
.service-carousel-container {
  padding: 25px 30px 45px 30px;
}
.services-carousel {
  width: 100px;
}
.footer {
  background-color: #e3831c;
  padding: 20px 0;
}
.legend {
  font-family: 'Brush Script', sans-serif;
  font-size: 24px;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  padding: 0 5px;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
.link-icon {
  position: relative;
  width: 12px;
  top: -3px;
}
.footer-israel {
  background-color: #4071B3;
  padding: 20px 0;
}
.legend-israel {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 0 5px;
  color: #fff;
}
.section-title {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 900;
  text-align: left;
  padding: 0 5px;
}
.business-desc_a {
  padding: 10px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}
.icons-contact-optica {
  color: #0090b3;
  font-size: 30px;
  cursor: pointer;
}
.footer-optica {
  background-color: #0090b3;
  padding: 20px 0;
}
.legend-optica {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 0 5px;
  color: #fff;
}
.footer-andrea {
  background-color: #E1831C;
  padding: 20px 0;
}
.legend-andrea {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding: 0 5px;
  color: #fff;
}
.icons-contact-andrea {
  color: #212529;
  font-size: 30px;
  cursor: pointer;
}
.banner_img {
  width: 90%;
}
.header-img-a {
  position: absolute;
  width: 100%;
  height: 400px;
  background-image: url(./img/ferresistemas/agua.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.header-img-b {
  position: absolute;
  width: 100%;
  height: 400px;
  background-image: url(./img/ferresistemas/mecanica.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  z-index: -1;
}
