@font-face {
  font-family: 'Brush Script';
  src: url(./fonts/Brush\ Script.ttf);
}
html, body {
  background-color: #f2f4fa;
}
.App {
  text-align: center;
  width: 100%;
}
.slick-prev:before, .slick-next:before {
  color: #464646;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

.background-andrea {
  background-image: url(./img/andrea/19860.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid rgb(232, 231, 231);
  width: 100%;
  height: calc(100% - 170px);;
  position: absolute;
  background-color: rgb(242, 242, 242);
  border-radius: 20px 20px 0 0;
  top: 120px;
  z-index: -1;
  box-shadow: 0px -4px 31px -15px rgba(0,0,0,0.38);
  opacity: 0.6;
}
.background-optica {
  background-image: url(./img/opticas/back.jpg);
  background-size: cover;
  background-repeat: repeat;
  border: 1px solid rgb(232, 231, 231);
  width: 100%;
  height: calc(100% - 170px);;
  position: absolute;
  background-color: rgb(242, 242, 242);
  border-radius: 20px 20px 0 0;
  top: 170px;
  z-index: -1;
  box-shadow: 0px -4px 31px -15px rgba(0,0,0,0.38);
}
.background-card {
  background-image: url(./img/ferresistemas/1743_a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border: 0px solid rgb(232, 231, 231);
  width: 100%;
  height: calc(100% - 170px);;
  position: absolute;
  background-color: rgb(242, 242, 242);
  /*border-radius: 20px 20px 0 0;*/
  top: 170px;
  z-index: -1;
  box-shadow: 0px -4px 31px -15px rgba(0,0,0,0.38);
}
.background-card-b {
  background-image: url(./img/ferresistemas/agua.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  border: 0px solid rgb(232, 231, 231);
  width: 100%;
  height: calc(100% - 170px);;
  position: absolute;
  background-color: rgb(242, 242, 242);
  /*border-radius: 20px 20px 0 0;*/
  top: 170px;
  z-index: -1;
  box-shadow: 0px -4px 31px -15px rgba(0,0,0,0.38);
}
.card-container {
  width: 100%;
  max-width: 500px;
  padding: 0 !important;
}
.business-image {
  width: 200px;
  padding: 10px 0 10px 0;
  border-radius: 20px;
}
.profile-image{
  width: 170px;
  padding: 10px 0 20px 0;
}
.profile-name {
  font-family: 'Roboto', sans-serif;
  font-size: 35px;
  font-weight: 900;
}
.profile-job {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #767676;
}
.contact-container {
  padding-top: 30px;
}
.subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 10px;
}
.icons-contact {
  color: #e3831c;
  font-size: 30px;
  cursor: pointer;
}
.business-desc {
  padding: 40px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  text-align: center;
}
.business-container{
  padding: 0px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}
.icons-rrss {
  color: #e3831c;
  font-size: 20px;
  cursor: pointer;
}
.service-carousel-container {
  padding: 25px 30px 45px 30px;
}
.services-carousel {
  width: 100px;
}
.footer {
  background-color: #e3831c;
  padding: 20px 0;
}
.legend {
  font-family: 'Brush Script', sans-serif;
  font-size: 24px;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  padding: 0 5px;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
.link-icon {
  position: relative;
  width: 12px;
  top: -3px;
}
.footer-israel {
  background-color: #4071B3;
  padding: 20px 0;
}
.legend-israel {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 0 5px;
  color: #fff;
}
.section-title {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 900;
  text-align: left;
  padding: 0 5px;
}
.business-desc_a {
  padding: 10px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}
.icons-contact-optica {
  color: #0090b3;
  font-size: 30px;
  cursor: pointer;
}
.footer-optica {
  background-color: #0090b3;
  padding: 20px 0;
}
.legend-optica {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 0 5px;
  color: #fff;
}
.footer-andrea {
  background-color: #E1831C;
  padding: 20px 0;
}
.legend-andrea {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding: 0 5px;
  color: #fff;
}
.icons-contact-andrea {
  color: #212529;
  font-size: 30px;
  cursor: pointer;
}
.banner_img {
  width: 90%;
}
.header-img-a {
  position: absolute;
  width: 100%;
  height: 400px;
  background-image: url(./img/ferresistemas/agua.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.header-img-b {
  position: absolute;
  width: 100%;
  height: 400px;
  background-image: url(./img/ferresistemas/mecanica.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  z-index: -1;
}
.user-profile {
  display: inline-block;
  width: 300px;
  height: 300px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/* Template1 */
.template1_loading {
  background-image: url(./img/NFC/template1/t1_loading.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.spinner-gray {
  color: #292929;
}
.contact-style {
  font-family: 'Montserrat', sans-serif; 
  font-size: 16px; 
  color: #fff; 
  padding: 10px 0px; 
  border-radius: 0px; 
  transition : 1000ms; 
  transform: translateY(0);
  padding-top: 15px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.contact-style-original {
  font-family: 'Montserrat', sans-serif; 
  font-size: 16px; 
  color: #fff; 
  padding: 10px 0px; 
  border-radius: 40px; 
  transition : 1000ms; 
  transform: translateY(0);
  padding-top: 15px;
}

/********* New Design **********/

.simple-card {
  background-color: #fff;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  padding: 0 0 20px 0;
}
.color-card {
  background-color: #fff;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  padding: 20px 0;
}
.rrss-container {
  border-radius: 30px;
  background-color: #fff;
  padding: 20px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.rrss-icons {
  width: 50px;
}
.rrss-text {
  padding-top: 10px;
  font-size: 11px;
}
.rrss-col {
  /*border-right: 1px solid #6f6f6f;
  border-left: 1px solid #6f6f6f;*/
  text-align: center;
  padding: 5px;
}
.navbar-custom {
  background-color: #fff !important;
  padding-bottom: 0 !important;
}
.share-icon {
  width: 30px;
  cursor: pointer;
  color: #292929;
  font-size: 20px;
}
